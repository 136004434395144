class Accordion {
  constructor(domNode) {
    this.domNode = domNode;
  }
  init() {
    this.controlledNode = false;
    var id = this.domNode.getAttribute('aria-controls');
    if (id) {
      this.controlledNode = document.getElementById(id);
    }

    if (this.domNode.getAttribute('aria-expanded') == null) {
      this.domNode.setAttribute('aria-expanded', 'false');
    }

    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
    this.domNode.addEventListener('click', this.handleClick.bind(this));
  }
  showContent() {
    this.domNode.setAttribute('aria-expanded', 'true');
  }
  hideContent() {
    this.domNode.setAttribute('aria-expanded', 'false');
  }
  toggleExpand() {
    if (this.domNode.getAttribute('aria-expanded') === 'true') {
      this.hideContent();
    }
    else {
      this.showContent();
    }
  }
  handleKeydown(event) {
    switch (event.code) {
      case "Enter":
      case "NumpadEnter":
      case "Space":
        this.toggleExpand();
        event.stopPropagation();
        event.preventDefault();
        break;
    }
  }
  handleClick() {
    this.toggleExpand();
  }
}

// accordionButtonElements = document.querySelectorAll('.accordion > button[aria-expanded][aria-controls]');
const initializeAccordions = function(accordionButtonElements) {
  const accordions = [];
  for (const accordionButtonElement of accordionButtonElements) {
    const accordion = new Accordion(accordionButtonElement);
    accordion.init();
    accordions.push(accordion);
  }
  return accordions;
}

export { Accordion, initializeAccordions };
