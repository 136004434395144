// import _ from 'lodash';
// import _join from 'lodash/join';

// see https://getbootstrap.com/docs/5.0/customize/optimize/
// import 'bootstrap';
import { Dropdown } from 'bootstrap'; // used for dropdown button on staff directory
import { createPopper } from '@popperjs/core';

import '@babel/polyfill';

import {
  fadeMainContent,
  unfadeMainContent,
  getMeta,
  getMicrosite,
  getLanguage,
  appendDebug,
  replaceTag,
  showConfetti,
  getXPathForElement,
  getElementByXPath
} from './js/gesis-helpers.js';

import { setupSkipLinks } from './js/gesis-accessibility.js';
import { tns, initializeSliderOptions, initializeSliders } from './js/gesis-slider.js';

import {
  Menubutton,
  initializeMegamenus,
  fadeMainContentAndHighlightThisMegamenuOnOpenHandler,
  unfadeMainContentAndHighlightThisMegamenuOnCloseHandler
} from './js/gesis-megamenu.js';

import { GesisSearch, initializeGesisSearch } from './js/gesis-search.js';
import { Popup, initializePopups } from './js/gesis-popup.js';
import { Accordion, initializeAccordions } from './js/gesis-accordion.js';
import { TabContainer, initializeTabContainers } from './js/gesis-tabs.js';
import { injectColorTable } from './js/gesis-css-custom-properties.js';
import './js/gesis-qa.js';
import { setupTracking } from './js/gesis-tracking.js';
import { VCard, initializeVCards } from './js/gesis-vcard.js';
import { setupGrisServiceProjectsTablesorters } from './js/gris-service.js';
import { setupParsley, setupPowermail } from './js/gesis-powermail.js';
import $ from 'jquery'; // this and webpack config's' "expose" needed to provide jQuery and $ in global scope for several third party extensions
import hljs from 'highlight.js';
// import 'tablesorter'; // default import pulls in jquery.tablesorter.combined.js, which with 94.7 kiB is unacceptably big
import 'tablesorter/dist/js/jquery.tablesorter'; // this import pulls in 42.2 kiB
import { Fancybox } from '@fancyapps/ui';

import {
  MicroModal,
  conditionallyShow404ModalOnActiveResolvingStrategy,
  initializeDialog
} from './js/gesis-dialog.js';

import confetti from 'canvas-confetti';
import Cookies from 'js-cookie';
import beautify from 'js-beautify';

import './scss/gesis-web.scss';

import DataTable from 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';

// create namespace for Gesis Web widget instances
window.GesisWeb = {};

// put helpers on global scope
window.fadeMainContent = fadeMainContent;
window.unfadeMainContent = unfadeMainContent;
window.getMeta = getMeta;
window.getMicrosite = getMicrosite;
window.getLanguage = getLanguage;
window.appendDebug = appendDebug;
window.replaceTag = replaceTag;
window.showConfetti = showConfetti;
window.getXPathForElement = getXPathForElement;
window.getElementByXPath = getElementByXPath;

window.setupSkipLinks = setupSkipLinks;

window.setupGrisServiceProjectsTablesorters = setupGrisServiceProjectsTablesorters;

window.setupPowermail = setupPowermail;

// BEGIN put widgets on global scope
window.confetti = confetti;
window.Cookies = Cookies;
window.hljs = hljs;
window.beautify = beautify;

window.tns = tns;
window.initializeSliderOptions = initializeSliderOptions;

window.Menubutton = Menubutton;
window.initializeMegamenus = initializeMegamenus;

window.GesisSearch = GesisSearch;

window.Popup = Popup;
window.initializePopups = initializePopups;

window.Accordion = Accordion;
window.initializeAccordions = initializeAccordions;

window.VCard = VCard;
window.initializeVCards = initializeVCards;

window.TabContainer = TabContainer;
window.initializeTabContainers = initializeTabContainers;

window.MicroModal = MicroModal;
window.initializeDialog = initializeDialog;

window.Fancybox = Fancybox;

window.DataTable = DataTable;

// END put widgets on global scope

setupParsley();

Fancybox.defaults.hideScrollbar = 'false'; // fix fancybox jerking background because of hiding scrollbar

document.addEventListener('DOMContentLoaded', function() {
  console.log("DOMContentLoaded");
  
  hljs.highlightAll();

  window.language = getLanguage();
  
  conditionallyShow404ModalOnActiveResolvingStrategy();
  
  // initialize VCards
  const vCardElements = document.getElementsByClassName("gs_vcard_toggle");
  const vCards = initializeVCards(vCardElements, fadeMainContent, unfadeMainContent);
  window.GesisWeb.vCards = vCards;
  
  // initialize GesisSearch
  const gesisSearchCombobox = document.getElementById('gs_gws_combobox');
  const gesisSearchInput = document.getElementById('gs_searchterm');
  const gesisSearchOutput = document.getElementById('gs_hitlist');
  const microsite = getMicrosite();
  
  const gesisSearchWidget = initializeGesisSearch(
    gesisSearchCombobox,
    gesisSearchInput,
    gesisSearchOutput,
    fadeMainContent,
    unfadeMainContent,
    microsite
  );
  GesisWeb.gesisSearchWidget = gesisSearchWidget;
  
  if (gesisSearchWidget) {
    // add GESIS Web-specific additional plugins
    const gesisSearchClearButton = document.getElementById('gs_search_clear');
    if (gesisSearchClearButton) {
      gesisSearchClearButton.addEventListener(
        'click',
        function() {
          gesisSearchWidget.clearInput();
          gesisSearchWidget.clearListbox();
        }
      );
      gesisSearchWidget.plugins.push(gesisSearchClearButton);
    }
    
    const gesisSearchToggleButton = document.getElementById('gs_search_toggle');
    if (gesisSearchToggleButton) {
      gesisSearchToggleButton.addEventListener(
        'click',
        function() {
          if (gesisSearchWidget.shown) {
            gesisSearchWidget.hideListbox();
          }
          else {
            gesisSearchWidget.showListbox();
          }
        }
      );
      gesisSearchWidget.plugins.push(gesisSearchToggleButton);
    }
  }
  
  // initialize Menubuttons / Megamenus
  const menubuttonElements = document.getElementsByClassName('gs_mm_toggle_button');
  const menubuttons = initializeMegamenus(
    menubuttonElements,
    fadeMainContentAndHighlightThisMegamenuOnOpenHandler,
    unfadeMainContentAndHighlightThisMegamenuOnCloseHandler
  );
  window.GesisWeb.menubuttons = menubuttons;
  
  setupSkipLinks();
  
  // initialize Popups / Rootline in breadcrumbs
  const popupElements = document.getElementsByClassName('gs_dropdown_toggle');
  const popups = initializePopups(popupElements);
  window.GesisWeb.popups = popups;
  
  // initialize tns / (accessible) sliders / carousels
  const sliderElements = document.querySelectorAll('.gs_3col_slider, .gs_single_slider, .gs_news_compact_slider, .gs_single_slider_wrapper');
  const sliders = initializeSliders(sliderElements);
  window.GesisWeb.sliders = sliders;
  
  // initialize Accordions
  const accordionButtonElements = document.querySelectorAll('.accordion > button[aria-expanded][aria-controls]');
  const accordions = initializeAccordions(accordionButtonElements);
  window.GesisWeb.accordions = accordions;
  
  // initialize Fancybox
  /* if an element has attribute 'data-fancybox',
   * then image_zoom / gs_lightbox / fancybox will be initialized by new fancybox version itself.
   * For legacy initialization, e.g. from news details template images,
   * we use the following jQuery initialization (see https://fancyapps.com/fancybox/3/docs/#initialize-with-javascript )
  */
  
  // initialize TabContainers
  const tabContainersElements = document.getElementsByClassName('tabs');
  const tabContainers = initializeTabContainers(tabContainersElements);
  window.GesisWeb.tabContainers = tabContainers;
  
  // initialize Gris Service Projects Tablesorters
  const grisServiceProjectsTableElements = document.querySelectorAll('.sortable-true.gris-service-projects-table');
  const grisServiceProjectsTablesorters = setupGrisServiceProjectsTablesorters(grisServiceProjectsTableElements);
  window.GesisWeb.grisServiceProjectsTablesorters = grisServiceProjectsTablesorters;
  // Tablesorter objects can then be programmatically interacted with e.g.
  // GesisWeb.grisServiceProjectsTablesorters[0].find('th:eq(2)').trigger('sort')
  // see https://mottie.github.io/tablesorter/docs/#methods
 

  //new DataTable(
  //document.getElementsByClassName('gesis-datatable')[0].getElementsByTagName("table")[0], 
  //{
    //ordering: true,
    //paging: true,
    //searching: true,
    //info: false
  //}); 

  const datatables = document.getElementsByClassName('gesis-datatable');
  for (const datatable of datatables){
    const datatableTables = datatable.getElementsByTagName("table");
    for (const datatableTable of datatableTables) {
      new DataTable (datatableTable);
    }
  }


  setupPowermail();
  
  injectColorTable();
  
  setupTracking();
});
