import $ from 'jquery';

// grisServiceProjectsTableElements = document.querySelectorAll('.sortable-true.gris-service-projects-table')
const setupGrisServiceProjectsTablesorters = function(grisServiceProjectsTableElements) {
  // Make projects table sortable
  const grisServiceProjectsTablesorters = [];
  for (const grisServiceProjectsTableElement of grisServiceProjectsTableElements) {
    const grisServiceProjectsTablesorter = $(grisServiceProjectsTableElement).tablesorter({
        dateFormat: "ddmmyyyy",
      });
    grisServiceProjectsTablesorters.push(grisServiceProjectsTablesorter);
  }
  
  return grisServiceProjectsTablesorters;
};

export { setupGrisServiceProjectsTablesorters };
