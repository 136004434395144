/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*   Adapted from https://w3c.github.io/aria-practices/examples/tabs/tabs-1/tabs.html
*   for GESIS-Web
*/

class TabContainer {
  constructor(domElement, automaticTabActivation = true) {
    this.domElement = domElement;
    this.automaticTabActivation = automaticTabActivation;
    /*
    see https://w3c.github.io/aria-practices/#keyboard-interaction-21
    WCAG WAI-ARIA Authoring Practices 1.2 recommends automatic tab
    activation on tab receiving focus, therefore defaulting to
    automaticTabActivation == true
    (example: https://w3c.github.io/aria-practices/examples/tabs/tabs-1/tabs.html )
    
    automaticTabActivation == false will use manual activation
    (example: https://w3c.github.io/aria-practices/examples/tabs/tabs-2/tabs.html )
    */
    
    // using querySelector, not querySelectorAll to select only the first,
    // allowing for nested tab containers
    this.tablist = this.domElement.querySelector('[role="tablist"]');
    
    this.tabheaders = Array.from( this.tablist.querySelectorAll('[role="tab"]') ); // button elements
    for (const tabheader of this.tabheaders) {
      tabheader.addEventListener('click', this.clickEventListener);
      tabheader.addEventListener('keydown', this.keydownEventListener);
    }
    
    // select all direct children tabpanels, allowing for nested tab containers
    // see https://stackoverflow.com/a/17206138/923560
    this.tabpanels = this.domElement.querySelectorAll(':scope > [role="tabpanel"]');
  }
  
  clickEventListener = event => {
    const tabheader = event.target;
    this.updateTabsStates(tabheader, true);
  }
  
  keydownEventListener = event => {
    switch (event.code) {
      case "End":
        event.preventDefault();
        const lastTabheader = this.tabheaders[this.tabheaders.length - 1];
        this.updateTabsStates(lastTabheader, this.automaticTabActivation);
        break;
      case "Home":
        event.preventDefault();
        const firstTabheader = this.tabheaders[0];
        this.updateTabsStates(firstTabheader, this.automaticTabActivation);
        break;
      case "Enter":
      case "NumpadEnter":
      case "Space":
        const currentTabheader = event.target;
        this.updateTabsStates(currentTabheader, true);
        break;
      case "ArrowLeft":
      case "ArrowRight":
        event.preventDefault();
        this.switchTabheaderOnArrowPress(event);
        break;
    }
  }
  
  switchTabheaderOnArrowPress(event) {
    const pressedKey = event.code;
    const targetTabheader = event.target; // the current button
    const currentPosition = this.tabheaders.indexOf(targetTabheader);
    let newTabheader;
    
    if (pressedKey === "ArrowLeft") {
      if (currentPosition === 0) {
        newTabheader = this.tabheaders[this.tabheaders.length - 1]; // last tab
      }
      else {
        newTabheader = this.tabheaders[currentPosition - 1];
      }
    }
    else if (pressedKey === "ArrowRight") {
      if ( currentPosition === (this.tabheaders.length - 1) ) {
        newTabheader = this.tabheaders[0]; // first tab
      }
      else {
        newTabheader = this.tabheaders[currentPosition + 1];
      }
    }
    
    if (newTabheader) {
      this.updateTabsStates(newTabheader, this.automaticTabActivation);
    }
    
  }
  
  updateTabsStates(interactedTabheader, activateInteractedTab) {
    // step one: update state for all tab headers
    for (const tabheader of this.tabheaders) {
      tabheader.setAttribute('tabindex', '-1');
      if (activateInteractedTab) {
        tabheader.setAttribute('aria-selected', 'false');
      }
    }
    
    // step two: update interacted tab
    interactedTabheader.removeAttribute('tabindex');
    interactedTabheader.focus();
    
    if (activateInteractedTab) {
      // optional step three: hide all panels
      for (const tabpanel of this.tabpanels) {
        tabpanel.classList.add('d-none');
      }
      
      // optional step four: show interacted tabpanel
      interactedTabheader.setAttribute('aria-selected', 'true');
      const toBeShownTabpanel = interactedTabheader.getAttribute('aria-controls');
      document.getElementById(toBeShownTabpanel).classList.remove('d-none');
    }
  }
  
}

function initializeTabContainers(tabContainersElements) {
  const tabContainers = [];
  for (const tabContainerElement of tabContainersElements) {
    const tabContainer = new TabContainer(tabContainerElement);
    tabContainers.push(tabContainer);
  }
  return tabContainers;
}

export { TabContainer, initializeTabContainers };
